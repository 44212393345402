import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '@core/services';
import { InfoMessagesService } from '@features/info-messages';
import { catchError, retry, throwError, timer } from 'rxjs';

const totalRetryCount = 3;
const retryDelay = 1000;

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const infoMessagesService = inject(InfoMessagesService);
  const log = inject(LogService);
  const router = inject(Router);

  return next(req).pipe(
    /**
     * Returns an observable that emits after `retryDelay` milliseconds if the error was a network error (status === 0).
     * Otherwise, rethrows the error. This is used to implement retry logic with a delay after network errors.
     * @param error the error that was thrown
     */
    retry({
      count: totalRetryCount,
      delay: (error: HttpErrorResponse) => {
        if (error.status === 0) {
          return timer(retryDelay);
        }
        return throwError(() => error);
      },
    }),
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 0:
          infoMessagesService.showError('Unexpected error. Please try again later.');
          break;
        case HttpStatusCode.Unauthorized:
          if (
            (error.error.code === 'user_inactive' || error.error.code === 'user_not_found') &&
            !req.url.includes('/me')
          ) {
            router.navigate(['/auth/logout']);
          }
          break;
        case HttpStatusCode.ExpectationFailed:
          break;
        case HttpStatusCode.NotFound:
          router.navigate(['page-not-found'], { skipLocationChange: true });
          break;
        case HttpStatusCode.BadRequest:
          break;
        case HttpStatusCode.Forbidden:
          break;
        default:
          log.warn('Unhandled HTTP error: ', error);
      }
      return throwError(() => error);
    }),
  );
};
