import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: false,
})
export class AppComponent {
  title = 'portal-web';
  // todo: remove once icons font is updated
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      `status_page`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/status_page.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `feedback`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/feedback.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `business-critical-connector`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/business_critical_connector.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      `standard-connector`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/standard_connector.svg'),
    );
  }
}
